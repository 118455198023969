import React from 'react';
import { Helmet } from 'react-helmet';

import './style.sass';


const Transparencia = () => (
  <main className="Transparencia">
    <Helmet>
      <title>Transparência Salarial 2024 | FRIBAL - Especialistas em Carne</title>
    </Helmet>
    <section className="Transparencia__content">
      <h2 className="Transparencia__title">Transparência Salarial 2024</h2>
      <div className="Transparencia__container">
        <section className="Transparencia__intro">
          <p>Em atendimento ao disposto na Lei Federal nº 14.611/2023, , na Portaria MTE Nº 3.714, bem como nos compromissos da FRIBAL para a promoção de práticas de inclusão e diversidade, divulgamos os Relatórios de Transparência Salarial e Critérios de Remuneração da RIO GRANDE FRIGORÍFICO IMPERATRIZ CNPJ: 07.555.950/0010-12, RIO GRANDE MATRIZ EMPÓRIO CALHAU CNPJ: 07.555.950/0001-21, BARRA CARNES CNPJ: 06.009.651/0001-29, COM CARNE FRIGORÍFICO SANTA INÊS CNPJ: 03.835. 761/0026-00, COM CARNE MATRIZ CD CNPJ 03.835.761/0001-51, RIO GRANDE EMPÓRIO COHAMA CNPJ: 07.555.950/0004-74, RIO GRANDE EMPÓRIO PENÍNSULA CNPJ: 07.555.950/0027-60 e RIO GRANDE EMPÓRIO PONTA D'AREIA CNPJ: 07.555.950/0029-22.</p>
          <p>As informações foram divulgadas pelo Ministério do Trabalho e Emprego de acordo com os Grandes Grupos da CBO (Classificação Brasileira de Ocupações) e os dados pessoais foram protegidos, nos termos da Lei Geral de Proteção de Dados Pessoais (Lei Federal nº 13.709/2018).</p>
          <p>Os relatórios a seguir foram elaborados pelo Ministério do Trabalho e Emprego, do Governo Federal, e levaram em conta informações do e-social do ano de 2022. O relatório utiliza uma classificação chamada “Grandes Grupos”, que reúne diferentes tipos de trabalho (cargos/departamentos).</p>
          <p>Para mais informações sobre as práticas de inclusão e diversidade da FRIBAL recomendamos consultar o nosso mais recente Relatório de Anual de Diversidade.</p>
        </section>
        <section className="Transparencia__files-container">
          <h3 className="Transparencia__subtitle">Documentos para download</h3>
          <a target="_blank" href="./docs/1T24_RIO_GRANDE_EMPORIO_PONTA_DAREIA_07555950002922.pdf">1T24 RIO GRANDE EMPÓRIO PONTA D'AREIA.PDF</a>
          <a target="_blank" href="./docs/1T24_RIO_GRANDE_COHAMA_07555950000474.pdf">1T24 RIO GRANDE COHAMA.PDF</a>
          <a target="_blank" href="./docs/1T24_RIO_GRANDE_EMPORIO_PENINSULA_07555950002760.pdf">1T24 RIO GRANDE EMPÓRIO PENÍNSULA.PDF</a>
          <a target="_blank" href="./docs/1T24_COMCARNE_FRIGORIFICO_STI_03835761002600.pdf">1T24 COMCARNE FRIGORÍFICO STI.PDF</a>
          <a target="_blank" href="./docs/1T24_COMCARNE_MATRIZ_CD_03835761000151.pdf">1T24 COMCARNE MATRIZ CD.PDF</a>
          <a target="_blank" href="./docs/1T24_BARRA_CARNES_06009651000129.pdf">1T24 BARRA CARNES.PDF</a>
          <a target="_blank" href="./docs/1T24_RIO_GRANDE_FRIGORIFICO_ITZ_07555950001012.pdf">1T24 RIO GRANDE FRIGORÍFICO ITZ.PDF</a>
          <a target="_blank" href="./docs/1T24_RIO_GRANDE_MATRIZ_EMPORIO_CALHAU_07555950000121.pdf">1T24 RIO GRANDE MATRIZ EMPÓRIO CALHAU.PDF</a>
        </section>
      </div>
    </section>

  </main>
);

export default Transparencia;
